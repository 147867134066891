<script lang="ts" setup>
import type { AlgoliaCategory } from '~/types/models/algoliaProduct';
import { useLayoutStoreSearch } from '~/composables/useLayoutStoreSearch';

const props = defineProps<{
    category: AlgoliaCategory;
    indexName: string;
    positionIndex: number; // algolia positions start with 1
}>();

const emits = defineEmits<{
    (e: 'close-search'): void;
}>();

const { trackAlgoliaObjectClick } = useAlgoliaTracking();
const { pathToSeoUrl } = useLanguageSelect();
const { categoryQueryId } = useLayoutStoreSearch();

const url = computed(() => pathToSeoUrl(props.category.seo_url));
const categoryName = computed(() => props.category._highlightResult.name.value || props.category.name);

const onCategoryClick = () => {
    trackAlgoliaObjectClick({
        objectIDs: [props.category.objectID],
        queryID: categoryQueryId.value,
        positions: [props.positionIndex],
        type: 'category',
    });
    emits('close-search');
};
</script>

<template>
    <NuxtLink
        :to="url"
        @click="onCategoryClick"
    >
        <span
            class="hover:text-brand-primary transition-colors"
            v-html="categoryName"
        />
    </NuxtLink>
</template>
